import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import RightArrow from "../../../src/assets/images/Frame 10.svg";
import "./Card6.css";
import { BASE_URL } from '../../Utils/Config';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';

const Card6 = () => {
  const [forums, setForums] = useState([]);
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isInView, controls]);

  useEffect(() => {
    const fetchForums = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admins/forums/`);
        setForums(response.data);
        
      } catch (error) {
        
      }
    };

    fetchForums();
  }, []);
  const getLatestThreeForums = () => {

    const sortedForums = forums.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


    const latestThreeForums = sortedForums.slice(0, 3);

    return latestThreeForums;
  };
  return (
    <div className="w-full h-full pt-6 bg-[#f4f4f4]">
      <div className="container">

       
          <motion.div
            className="grid grid-cols-1 lg:grid-cols-3 gap-6"
            initial={{ opacity: 0, y: 50 }}
            animate={controls} 
            transition={{ duration: 0.6 }}
            ref={ref}
          >
            {getLatestThreeForums().map((forum) => (
              <div key={forum.id} className="bg-[#C5E5FF] rounded-[30px]">
                <Link to={`/speciality-forum/${forum.slug}`}>
                  <div className="relative">
                    <img src={forum.image} alt="Forum Image" className="w-full  rounded-[30px]" />
                    <img src={RightArrow} alt="Right Arrow" className="absolute right-3 top-8 transform -translate-y-1/2" />
                  </div>
                </Link>
                <Link to={`/speciality-forum/${forum.slug}`}>
                  <p className="text-[#222] p-4 text-center pl-8 text-[21px] not-italic font-semibold leading-[normal] forumhead">{forum.title}</p>
                </Link>
              </div>
            ))}
          </motion.div>
        

      </div>
    </div>
  );
};

export default Card6;
