import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../../../Utils/Config';
import { getUserProfile } from '../../../../helpers/auth';
import "./changePswrd.css";

const Password = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [displayedPassword, setDisplayedPassword] = useState('');
    const [userProfile, setUserProfile] = useState(null);
 
    const [user, setUser] = useState(null);
    const authToken = localStorage.getItem('authToken');
     
    const userId = localStorage.getItem('userId');
    
    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        try {
            if (userId && authToken) {
               
                const profile = await getUserProfile(userId);
                setUser(profile.user);
                setUserProfile(profile);
                toast.success('User profile fetched successfully');
            } else {
                toast.error('User ID or authentication token is missing');
            }
        } catch (error) {
          
            toast.error('Error fetching user profile');
        }
    };
    useEffect(() => {
        fetchUserProfile();

        return () => {

        };
    }, [userId, authToken]);
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        setDisplayedPassword('★'.repeat(value.length));
    };

    const handlePasswordChange1 = (event) => {
        setPassword1(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.post(
                `${BASE_URL}/accounts/change-password/`,
                {
                    // email,
                    new_password: password1,
                    confirm_password: password1,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
           
            alert('Password changed successfully');
         
            // setEmail('');
            setPassword('');
            setPassword1('');
            setDisplayedPassword('');
        } catch (error) {
            
            toast.error('Error changing password');
        }
    };
    

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6'>
                <div className='passwrd-box'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Change Password</p>
                <div className="text-start">
                    {/* <p className="text-gray-600  pt-4 text-[18px] not-italic font-semibold leading-[24px]">Password*</p>
                    <div className="pt-4">
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            className=" border rounded-[10px] px-[30px] py-4    bg-white w-[516px]"
                            placeholder="Email address"
                        /> */}
                    {/* </div> */}
                    <p className="text-[#444150] pt-4 text-[18px] not-italic font-semibold leading-[24px] passowrd-label">New Password*</p>
                    <div className="relative pt-4">
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            className="border rounded-[10px] px-[30px] py-4   pt-6 bg-white "
                            style={{ font: 'normal 1em Arial' }}
                            placeholder="************"
                        />
                    </div>
                    <p className="text-[#444150] pt-4 text-[18px] not-italic font-semibold leading-[24px] passowrd-label">Confirmed Password*</p>
                    <div className="relative pt-4">
                        <input
                            type="password"
                            value={password1}
                            onChange={handlePasswordChange1}
                            className="border rounded-[10px] px-[30px] py-4   pt-6 bg-white "
                            style={{ font: 'normal 1em Arial' }}
                            placeholder="************"
                        />
                    </div>
                    <div className='pt-8'>
                        <button onClick={handleSubmit} className='bg-[#00549A] rounded-[10px]  py-4'>
                            <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Set Password</p>
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Password;
