import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { BASE_URL } from '../../Utils/Config';
import Logo11 from "../../../src/assets/images/logo2.png";
import Logo12 from "../../../src/assets/images/Ellipse 4.png";
import Logo13 from "../../../src/assets/images/elements.png";
import Logo14 from "../../../src/assets/images/Frame 1000004235.jpg";
import Logo15 from "../../../src/assets/images/Frame 1000004235.jpg";
import Logo27 from "../../../src/assets/images/Frame 10.svg";
import './Card4.css';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';

const Card4 = () => {

    const [blogs, setBlogs] = useState([]);

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const [generalBlogs, setGeneralBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);

    // useEffect(() => {
    //     const fetchLatestBlog = async () => {
    //         try {
    //             const response = await axios.get(`${BASE_URL}/admins/blogslist/`);
    //             const blogs = response.data;
    //             if (blogs.length > 0) {

    //                 blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
    //                 setLatestBlog(blogs[0]);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching blogs:', error);
    //         }
    //     };

    //     fetchLatestBlog();
    // }, []);
    const [latestBlog, setLatestBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Fetch general and regular blogs
                const [blogsResponse, generalBlogsResponse] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/blogslist/`),
                    axios.get(`${BASE_URL}/admins/blogsgeneral/`)
                ]);

                setBlogs(blogsResponse.data);
                setGeneralBlogs(generalBlogsResponse.data);

                // Combine and sort blogs
                const combinedBlogs = [...blogsResponse.data, ...generalBlogsResponse.data];
                const sortedBlogs = combinedBlogs.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Set latest blog (first in the sorted array)
                setLatestBlog(sortedBlogs[0]);

                // Remove latest blog from the remaining blogs
                const remainingBlogs = sortedBlogs.slice(1);
                setBlogs(remainingBlogs);

            } catch (error) {
               
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const [latestPodcast, setLatestPodcast] = useState(null);
    const fetchPodcast = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/podcast_listall/`);
           
    
          
            if (Array.isArray(response.data) && response.data.length > 0) {
             
                const sortedPodcasts = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                const latest = sortedPodcasts[0];  
    
           
                if (latest) {
                    setLatestPodcast(latest);
                } else {
                   
                }
            } else {
                
            }
    
        } catch (error) {
            
        }
    };
    
    useEffect(() => {
        fetchPodcast();
    }, []);
    
    const formatTimeTo12Hour = (timeString) => {
        let [hours, minutes] = timeString.split(':'); // Split the time into hours and minutes
        let period = 'AM'; // Default to AM

        hours = parseInt(hours);

        // Convert from 24-hour to 12-hour format
        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) hours -= 12; // Convert 13-23 to 1-11
        } else if (hours === 0) {
            hours = 12; // Convert 00:xx to 12:xx AM
        }

        return `${hours}:${minutes} ${period}`;
    };

    return (
        <div className="w-full h-full pt-6 bg-[#f4f4f4]">
            <div className="container">
                <motion.div
                    className="  "
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 4 }}
                    transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                    }}
                    ref={ref}
                >
                    <div className=" ">
                        <div className=" bg-[#00549A] rounded-[30px] p-explore  p-m12">
                            <p className="text-white text-start text-[32px] not-italic font-bold leading-normal"  >Explore FCPI</p>
                            <div className="flex blocksection pt-8 pb-20 m-pb-20  m-pt-8 ">
                                <p className="text-[#FFF] text-[17px] text-m-17 text-start not-italic font-normal leading-[26px] mt-000"  >Explore the Federation of Clinical Pharmacists in India (FCPI), a premier organization committed to advancing the clinical pharmacy profession.<br/> FCPI focuses on education, research, and advocacy,  empowering pharmacists to enhance patient care and medication management.<br/>
                                    Join a vibrant community dedicated to improving healthcare outcomes through collaboration and innovative practices.

                                </p>



                            </div>
                            <div className="grid grid-cols-3 rounded-[30px] gap-6 pt-12 explore-grids">

                                <div>
                                    <motion.div
                                        className="flex flex-col bg-white rounded-[30px]"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={controls}
                                        transition={{ duration:0.6 }}
                                        ref={ref}

                                    >
                                        {latestBlog && (
                                            <div key={latestBlog.slug} className="flex flex-col bg-white rounded-[30px] ">
                                                <div className="relative">
                                                    <Link
                                                        to={generalBlogs.some(b => b.slug === latestBlog.slug) ? `/general-blogs-detail/${latestBlog.slug}` : `/blogs-detail/${latestBlog.slug}`}
                                                    >
                                                        <div>
                                                            <img src={BASE_URL + latestBlog.blog_banner} alt="Blog Banner" className="w-[100%] rounded-[30px]" />
                                                        </div>
                                                    </Link>
                                                    <div className="explore-section-img">
                                                        <div className="explore-name">
                                                            <div><p className="explore-name-btn">Latest Blog</p></div>
                                                            <div><Link to="/blogs">
                                                                <img src={Logo27} alt="Right Arrow" className="rounded-[30px]" />
                                                            </Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link
                                                    to={generalBlogs.some(b => b.slug === latestBlog.slug) ? `/general-blogs-detail/${latestBlog.slug}` : `/blogs-detail/${latestBlog.slug}`}
                                                >
                                                    <div className="blog-title">
                                                        <p className="text-[#222] text-start p-8 pall-80 text-[21px] not-italic font-semibold leading-[normal] explorefcpi">
                                                            {latestBlog.title}
                                                        </p>
                                                    </div>
                                                </Link>
                                                <div className="w-full flex justify-start p-4 ">
                                                    <hr className="w-[100%] border border-[#EEE]" />
                                                </div>
                                                <div className=' p-8 pall-80 w-full'>
                                                    <div className="inline-flex items-center">
                                                        <div>
                                                            <img src={BASE_URL + latestBlog.author_profile} alt="Author Profile" className="rounded-[30px] w-[40px] h-[40px]" />
                                                        </div>
                                                        <div className='pt-1'>
                                                        <p className="text-[#58585A] pl-3 text-[14px] not-italic  font-semibold leading-[normal] explorefcpipublish">{latestBlog.author}</p>
                                                        <p className="text-[#58585A] text-[14px] not-italic pl-3 pt-1 font-normal leading-[normal] explorefcpipublish">Published on {formatDate(latestBlog.date)}</p>
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                                <div className="blog-readmore-btn">
                                                    <Link to="/blogs">
                                                        <button className="inline-flex mx-178 p-4 justify-center shrink-0 rounded-[105px] bg-[#00549a]">
                                                            <p className="text-white text-[14px] not-italic font-semibold leading-[normal]">View All</p>
                                                            <div className="pl-4 pt-1">
                                                                <img src={Logo13} alt="Logo" className="rounded-[30px]" />
                                                            </div>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </motion.div>
                                </div>

                                <motion.div
                                    className="flex flex-col bg-white rounded-[30px]"
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={controls}
                                    transition={{ duration: 1.5 }}
                                    ref={ref}
                                >

                                    <div className="relative">
                                        <img src={Logo14} alt="Logo" className="w-[100%] rounded-[30px]" />
                                        <div className='explore-section-img'>
                                            <div className='explore-name'>
                                                <div> <p className="explore-name-btn" >Journal watch</p> </div>
                                                <div> <img src={Logo27} alt="Right Arrow" className=" rounded-[30px]" /> </div>
                                            </div>
                                        </div>


                                    </div>
                                    <p className="text-[#222] text-start p-8 pall-80 text-[21px] not-italic font-semibold leading-[normal] explorefcpi"  > Coming Soon</p>
                                    <div className="w-full flex justify-start ml-8 ">
                                        {/* <hr className=" w-[87%] border border-[#EEE]" /> */}

                                    </div>

                                    {/* <div className="inline-flex items-center p-8 pall-80 w-full ">
                                        <div className='w-[32px]'>
                                            <img src={Logo12} alt="Author Profile" className="rounded-[30px]" />
                                        </div>

                                        <p className="text-[#58585A] pl-3 text-[14px] not-italic w-[74%] font-semibold leading-[normal] explorefcpipublish">Samuel Gordalina </p>
                                        <p className="text-[#58585A]  text-[14px] not-italic font-normal leading-[normal] explorefcpipublish">2024-06-13</p>

                                    </div> */}



                                    <div className="blog-readmore-btn">
                                        <button className="inline-flex mx-178 p-4 justify-center shrink-0 rounded-[105px] bg-[#00549a]">
                                            <p className="text-white text-[14px] not-italic font-semibold leading-[normal]">View All</p>
                                            <div className="pl-4 pt-1">
                                                <img src={Logo13} alt="Logo" className="rounded-[30px]" />
                                            </div>
                                        </button>
                                    </div>


                                </motion.div>


                                <div className='flex'>
                                    <motion.div
                                        className="flex flex-col bg-white rounded-[30px]"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={controls}
                                        transition={{ duration: 0.6 }}
                                        ref={ref}

                                    >
                                        {latestPodcast && (
                                            <div key={latestPodcast.id} className="flex flex-col bg-white rounded-[30px] ">
                                                <div className="relative">
                                                    <Link to={`/podcast/${encodeURIComponent(latestPodcast.name)}`}>
                                                        <div>
                                                            <img src={latestPodcast.image} alt="Blog Banner" className="w-[100%] rounded-[30px]" />
                                                        </div>
                                                    </Link>
                                                    <div className="explore-section-img">
                                                        <div className="explore-name">
                                                            <div><p className="explore-name-btn">Latest Podcast</p></div>
                                                            <div><Link to="/podcasts">
                                                                <img src={Logo27} alt="Right Arrow" className="rounded-[30px]" />
                                                            </Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to={`/podcast/${encodeURIComponent(latestPodcast.name)}`}>
                                                    <div className="blog-title">
                                                        <p className="text-[#222] text-start p-8 pall-80 text-[21px] not-italic font-semibold leading-[normal] explorefcpi">
                                                            {latestPodcast.name}
                                                        </p>
                                                    </div>
                                                </Link>
                                                <div className="w-full flex justify-start p-4 ">
                                                    <hr className="w-[100%] border border-[#EEE]" />
                                                </div>
                                                <div className="inline-flex  gap-4 items-center p-8 pall-80 w-full">
                                                    <div>
                                                        <img src={latestPodcast.banner} alt="Author Profile" className="rounded-[30px] w-[40px] h-[40px]" />
                                                    </div>
                                                    <div>
                                                        <p className="text-[#58585A] text-[14px] not-italic font-normal leading-[normal] explorefcpipublish">{formatDate(latestPodcast.date)}</p>
                                                        <div className='flex w-full pt-1'>
                                                            <p className="text-[#58585A] text-[14px] not-italic font-semibold leading-[normal] explorefcpipublish">
                                                                {formatTimeTo12Hour(latestPodcast.starting_time)}
                                                            </p>
                                                            <p className="text-[#58585A] pl-3 text-[14px] not-italic  font-semibold leading-[normal] explorefcpipublish">
                                                                {formatTimeTo12Hour(latestPodcast.ending_time)}  (IST)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="blog-readmore-btn">
                                                    <Link to="/podcasts">
                                                        <button className="inline-flex mx-178 p-4 justify-center shrink-0 rounded-[105px] bg-[#00549a]">
                                                            <p className="text-white text-[14px] not-italic font-semibold leading-[normal]">View All</p>
                                                            <div className="pl-4 pt-1">
                                                                <img src={Logo13} alt="Logo" className="rounded-[30px]" />
                                                            </div>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </motion.div>
                                </div>


                            </div>
                        </div>
                    </div>
                </motion.div>
            </div >
        </div >
    );
};

export default Card4;
