import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from '../../../../Utils/Config';
import Log from '../../../../assets/images/login.png';
import { toast, Toaster } from 'react-hot-toast';
import "./resetpass.css";
const ResetPassword = () => {
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const emailParam = new URLSearchParams(location.search).get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);
    const isLoggedIn = () => {

        return !!localStorage.getItem('authToken');
    };


    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/dashboard');
        }
    }, [navigate]);
    const handlePasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BASE_URL}/accounts/reset-password/`, {
                email: email,
                new_password: newPassword,
                confirm_password: confirmPassword,
            });

            if (response.status === 200) {
                toast.success(response.data.message);
                navigate('/login');

            }
        } catch (error) {
           
            toast.error(error.response?.data?.error || 'Failed to reset password. Please try again.');
        }
    };
    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {

                navigate('/dashboard');
            }
        };
        checkAuth();
    }, [navigate]);

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <Toaster position="top-center" reverseOrder={false} />

                <div className="pt-6">
                    <div className="flex mob-block bg-white rounded-[30px] p-6 md:p-12 gap-6 reset-pass-main">
                        <div className="relative xl:w-1/2 lg:w-1/2 md:w-full">
                            <img src={Log} alt="" className="rounded-[30px] w-[764px] reset-pass-img" />
                            <p className="absolute left-10 bottom-10 text-[color:var(--White,#FFF)] text-[34px] not-italic font-semibold leading-[36px] reset-pass-text">
                                Towards Clinical
                                <br />
                                Pharmacy Excellence
                            </p>
                        </div>
                        <div className='forgetpaswd reset-pass-container'>
                            <div className=" h-[100vh] lg:p-6">
                                <div className="bg-[#f4f4f4] fr-section p-6 reset-pass-section">

                                    <h4 className="text-[#222] font-semibold">Reset Password</h4>
                                    <div className="text-start">
                                        <p className="text-[#444150] pt-4 text-18px font-semibold reset-pass-labels">New Password</p>
                                        <div className="relative pt-4 reset-pass-inputs">
                                            <input
                                                type="password"
                                                value={newPassword}
                                                onChange={handlePasswordChange}
                                                className="password-input"
                                                placeholder="Enter new password"
                                            />
                                        </div>

                                        <p className="text-[#444150] pt-6 text-18px font-semibold">Confirm Password</p>
                                        <div className="relative pt-4 reset-pass-inputs">
                                            <input
                                                type="password"
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                                className="password-input"
                                                placeholder="Confirm new password"
                                            />
                                        </div>


                                        <div className="pt-4 reset-pass-btn">
                                            <button onClick={handleResetPassword} className="verify-button bg-[#00549A] button-bgblues p-lr-20 rounded-10px w-516px py-4">
                                                <p className="text-white text-center text-20px font-semibold">Reset Password</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;






























