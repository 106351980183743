import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from "../../../src/assets/images/FCPI Logo 1.svg";
import logo1 from "../../../src/assets/images/user-story.png";
import logo2 from "../../../src/assets/images/user-add--01.png";
import logo3 from "../../../src/assets/images/user-story.svg";
import axios from "axios";
import { BASE_URL } from '../../Utils/Config';
import { getLocal } from '../../helpers/auth';
import { isTokenExpired } from '../../helpers/auth';
import acc from "../../assets/images/user-account.png";
import acc5 from "../../assets/images/user-story.svg";
import acc1 from "../../assets/images/user3.png";
import acc2 from "../../assets/images/square.png";
import acc3 from "../../assets/images/logout.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import menu from "../../assets/images/Mneu.png";
import close from "../../assets/images/cancel-01.png";

import './index.css';


const
    Home = () => {
        const [showAboutList, setShowAboutList] = useState(false);
        const [hoveredLogin, setHoveredLogin] = useState(false);
        const [isAboutDropdownHovered, setIsAboutDropdownHovered] = useState(false);
        const [isBlogDropdownHovered, setIsBlogDropdownHovered] = useState(false);
        const [showSpecialtyList, setShowSpecialtyList] = useState(false);
        const [forums, setForums] = useState([]);
        const location = useLocation();
        const isActive = location.pathname === '/register';
        const [isLoggedIn, setIsLoggedIn] = useState(false);
        const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
        const navigate = useNavigate();
        const user_auth = getLocal();
        const [isMobileView, setIsMobileView] = useState(false);
        const [showDropdown, setShowDropdown] = useState(false);



        // function checkTokenAndRedirect() {
        //     const token = getLocal();
        //     if (token && !isTokenExpired(token)) {

        //     } else {

        //         window.location.href = '/login';
        //     }
        // }

        // checkTokenAndRedirect();

        useEffect(() => {
            const checkAuthStatus = () => {
                const authToken = localStorage.getItem('authToken');
                setIsLoggedIn(!!authToken);
            };

            checkAuthStatus();

            // Listen for authentication changes if needed
            const handleStorageChange = () => {
                checkAuthStatus();
            };

            window.addEventListener('storage', handleStorageChange);

            return () => {
                window.removeEventListener('storage', handleStorageChange);
            };
        }, []);



        const handleLoginClick = () => {
            const currentPath = window.location.pathname;
            localStorage.setItem('loginRedirectPath', currentPath);
            navigate('/login');
        };
        ;


        // useEffect(() => {
        //     const user_auth = getLocal();
        //     const isAuthValid = user_auth && !isTokenExpired(user_auth);
        //     setIsLoggedIn(isAuthValid);
        // }, []);
        useEffect(() => {

            const handleResize = () => {
                setIsMobileView(window.innerWidth <= 1280);
            };


            handleResize();

            window.addEventListener("resize", handleResize);


            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);
        const handleLoginMouseEnter = () => {
            setHoveredLogin(true);
        };

        const handleLoginMouseLeave = () => {
            setHoveredLogin(false);
        };

        const toggleAccountDropdown = () => {
            setIsAccountDropdownOpen(!isAccountDropdownOpen);
        };

        const handleAboutMouseEnter = () => {
            setIsAboutDropdownHovered(true);
        };

        const handleAboutMouseLeave = () => {
            setIsAboutDropdownHovered(false);
        };

        const handleAccountMouseEnter = () => {
            setIsAccountDropdownOpen(true);
        };

        const handleAccountMouseLeave = () => {
            setIsAccountDropdownOpen(false);
        };

        const handleBlogMouseEnter = () => {
            setIsBlogDropdownHovered(true);
        };

        const handleBlogMouseLeave = () => {
            setIsBlogDropdownHovered(false);
        };

        const handleSpecialtyMouseEnter = () => {
            setShowSpecialtyList(true);
        };

        const handleSpecialtyMouseLeave = () => {
            setShowSpecialtyList(false);
        };

        const handleDropdownMouseEnter = () => {
            setIsAboutDropdownHovered(true);
        };

        const handleDropdownMouseLeave = () => {
            setIsAboutDropdownHovered(false);
        };
        const handleBlogDropdownMouseEnter = () => {
            setIsBlogDropdownHovered(true);
        };

        const handleBlogDropdownMouseLeave = () => {
            setIsBlogDropdownHovered(false);
        };
        useEffect(() => {
            const fetchForums = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/admins/forums/`);

                    setForums(response.data);
                } catch (error) {
                    
                }
            };

            fetchForums();
        }, []);




        const handleLogout = () => {
            localStorage.removeItem('authToken');
            setIsLoggedIn(false);
            navigate('/');
        };

        const [showAboutDropdown, setShowAboutDropdown] = useState(false);
        const handleAboutLinkClick = () => {
            setShowAboutDropdown(!showAboutDropdown);
        };
        const [showBlogDropdown, setShowBlogDropdown] = useState(false);
        const handleBlogLinkClick = () => {
            setShowBlogDropdown(!showBlogDropdown);
        };

        const [showSpecialityDropdown, setShowSpecialityDropdown] = useState(false);
        const handleSpecialityClick = () => {
            setShowSpecialityDropdown(!showSpecialityDropdown);
            setShowSpecialtyList(true);
        };
        const [showSpclDropdown, setShowSpclDropdown] = useState(false);
        const handleSpclLinkClick = () => {
            setShowSpclDropdown(!showSpclDropdown);
        };
        const [toggleIcon, setToggleIcon] = useState(true);
        const handleIconToggle = () => {
            setToggleIcon(!toggleIcon);
            setShowDropdown(false);
        };
        const handleDropdownToggle = () => {
            setShowDropdown(!showDropdown);
            setToggleIcon(!toggleIcon);
        };
        return (
            <div className="w-100% h-100% bg-[#f4f4f4]">
                <div className="container">
                    <div className="header mob-padding-0">
                        <div className=" pt-6 mpt-6 logopl mob-div  ">
                            <div>
                                <Link to="/">  <img src={logo} alt="Logo" className="logo-fcpi" /> </Link>
                            </div>

                            <div>
                                {isMobileView && (
                                    <div className="mobile-only">
                                        <div>
                                            <button className="ellipsis-button" onClick={handleDropdownToggle}>
                                                <img
                                                    src={toggleIcon ? menu : close}
                                                    alt="Menu"
                                                    className={`menu-image ${toggleIcon ? 'rotate-open' : 'rotate-close'}`}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>


                        <div className={`bg-white menu-mobile ${showDropdown ? 'dropdown-animation' : ''}`}>
                            <div className="dropdown-menu text-start">
                                <ul className="mobile-menuul">
                                    <li>
                                        <div>
                                            <Link to="#" className="about-us-button" onClick={handleAboutLinkClick}>
                                                About Us <FontAwesomeIcon icon={showAboutDropdown ? faAngleUp : faAngleDown} />
                                            </Link>

                                            <div className={`dropdown-menu-wrapper ${showAboutDropdown ? 'dropdown-animation' : ''}`}>
                                                <div className="dropdown-menu p-6">
                                                    <ul>
                                                        <li>
                                                            <Link to="/about-fcpi">
                                                                <p className="menutext font-normal px-3 py-1">About FCPI</p></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/board-of-directors">
                                                                <p className="menutext font-normal px-3 py-1">Board of Directors</p></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/forum-members">
                                                                <p className="menutext font-normal px-3 py-1">Forum Members</p></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/committees">
                                                                <p className="menutext font-normal px-3 py-1">Committees</p></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Link to="#" className="" onClick={handleSpecialityClick}>
                                                Specialty Forums <FontAwesomeIcon icon={showSpecialityDropdown ? faAngleUp : faAngleDown} />
                                            </Link>

                                            <div className={`dropdown-menu-wrapper ${showSpecialityDropdown ? 'dropdown-animation' : ''}`}>
                                                <div className="dropdown-menu p-5">
                                                    <ul>
                                                        {forums.map((forum) => (
                                                            <li key={forum.id}>
                                                                <Link to={`/speciality-forum/${forum.slug}`}>
                                                                    <p className="menutext font-normal px-3 py-1">
                                                                        {forum.title}
                                                                    </p>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/events">Events</Link>
                                    </li>
                                    <li>
                                        <div>
                                            <Link to="#" className=" " onClick={handleBlogLinkClick}>
                                                Media Center <FontAwesomeIcon icon={showBlogDropdown ? faAngleUp : faAngleDown} />
                                            </Link>

                                            <div className={`dropdown-menu-wrapper ${showBlogDropdown ? 'dropdown-animation' : ''}`}>
                                                <div className="dropdown-menu p-6">
                                                    <ul>
                                                        <li>
                                                            <Link to="/blogs">
                                                                <p className="menutext font-normal px-3 py-1">Blogs</p></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/podcasts">
                                                                <p className="menutext font-normal px-3 py-1">Podcast</p></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/newsletters">
                                                                <p className="menutext font-normal px-3 py-1">Newsletter</p></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <Link to="/gallery">Gallery</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>

                        </div>


                        <div className="pt-6 pl-16 deskhide ">

                            <div className=" flex bg-white rounded-[150px]">

                                <Link to=""
                                    className={`justify-center items-center px-8 py-[16px] rounded-[105px] bg-${location.pathname.startsWith('/about') ? '[#00549a]' : 'white'} text-${location.pathname.startsWith('/about') ? 'white' : '[#222]'} hover:bg-[#00549a] hover:text-white about-us-button`}
                                    onMouseEnter={handleAboutMouseEnter}
                                    onMouseLeave={handleAboutMouseLeave}
                                >
                                    <p className="menutext ">About Us</p>
                                    {isAboutDropdownHovered && (
                                        <div
                                            className={`about-list show`}
                                            onMouseEnter={handleDropdownMouseEnter}
                                            onMouseLeave={handleDropdownMouseLeave}
                                        >
                                            <ul className="drop-menu">
                                                <li className="">
                                                    <Link to="/about-fcpi">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1" style={{ display: "block" }}>About FCPI</p>
                                                    </Link>
                                                </li>
                                                <li className="">
                                                    <Link to="/board-of-directors">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1 " style={{ display: "block" }}>Board of Directors</p>
                                                    </Link>
                                                </li>
                                                <li className="">
                                                    <Link to="/committees">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1 " style={{ display: "block" }}>Committees</p>
                                                    </Link>
                                                </li>
                                                <li className="">
                                                    <Link to="/forum-members">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1 " style={{ display: "block" }}>Forum Members</p>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </Link>
                                <div
                                    className={`relative justify-center items-center menu-padding py-[16px] rounded-[105px] bg-${location.pathname.includes("/speciality-forum")
                                        ? "[#00549A]"
                                        : "white"
                                        } text-${location.pathname.includes("/speciality-forum")
                                            ? "white"
                                            : "black"
                                        } hover:bg-[#00549a] hover:text-white`}
                                    onMouseEnter={handleSpecialtyMouseEnter}
                                    onMouseLeave={handleSpecialtyMouseLeave}
                                >
                                    <p className="menutext ">
                                        Specialty Forums
                                    </p>
                                    {showSpecialtyList && (
                                        <div className="forum-list show">
                                            <ul className="drop-menu">
                                                {forums.map((forum) => (
                                                    <li key={forum.id}>
                                                        <Link to={`/speciality-forum/${forum.slug}`}>
                                                             
                                                            <p className="menutext font-normal px-3 py-1 ">
                                                                {forum.title}
                                                            </p>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <Link
                                    to="/events"
                                    className={`justify-center items-center menu-padding py-[16px] rounded-[105px] bg-${location.pathname.includes('/events') ? '[#00549A]' : 'white'} text-${location.pathname.includes('/events') ? 'white' : 'black'} hover:bg-[#00549a] hover:text-white`}
                                >
                                    <div>
                                        <p className="menutext">Events</p>
                                    </div>
                                </Link>
                          


                                <Link to=""
                                    className={`justify-center items-center px-8 py-[16px] rounded-[105px] bg-${location.pathname.startsWith('/blog') ? '[#00549a]' : 'white'} text-${location.pathname.startsWith('/blog') ? 'white' : '[#222]'} hover:bg-[#00549a] hover:text-white about-us-button`}
                                    onMouseEnter={handleBlogMouseEnter}
                                    onMouseLeave={handleBlogMouseLeave}
                                >
                                    <p className="menutext ">Media Center</p>
                                    {isBlogDropdownHovered && (
                                        <div
                                            className={`blog-list show`}
                                            onMouseEnter={handleBlogDropdownMouseEnter}
                                            onMouseLeave={handleBlogDropdownMouseLeave}
                                        >
                                            <ul className="drop-menu">
                                                <li className="">
                                                    <Link to="/blogs">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1" style={{ display: "block" }}>Blogs</p>
                                                    </Link>
                                                </li>
                                                <li className="">
                                                    <Link to="/podcasts">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1 " style={{ display: "block" }}>Podcast</p>
                                                    </Link>
                                                </li>
                                                <li className="">
                                                    <Link to="/newsletters">
                                                        <p className="menutext font-normal text-[14px] not-italic px-3 py-1 " style={{ display: "block" }}>Newsletter</p>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                    )}
                                </Link>



                                <Link
                                    to="/gallery"
                                    className={`justify-center items-center menu-padding py-[16px] rounded-[105px] bg-${location.pathname.includes('/gallery') ? '[#00549A]' : 'white'} text-${location.pathname.includes('/gallery') ? 'white' : 'black'} hover:bg-[#00549a] hover:text-white`}
                                >
                                    <div>
                                        <p className="menutext">Gallery</p>
                                    </div>
                                </Link>
                                <Link
                                    to="/contact"
                                    className={`justify-center items-center menu-padding py-[16px] rounded-[105px] bg-${location.pathname.includes('/contact') ? '[#00549A]' : 'white'} text-${location.pathname.includes('/contact') ? 'white' : 'black'} hover:bg-[#00549a] hover:text-white`}
                                >
                                    <div>
                                        <p className="menutext">Contact Us</p>
                                    </div>
                                </Link>
                            </div>
                        </div>



                        <div className=" mob-pb-20 inline-block">
                            {isLoggedIn ? (
                                <div className="relative myacc-btn-pl">
                                    <button className="myac-btn"
                                        onMouseEnter={handleAccountMouseEnter}
                                        onMouseLeave={handleAccountMouseLeave}
                                        onClick={toggleAccountDropdown}  >
                                         My Account
                                         <img src={acc5} alt="" />
                                        
                                    </button>
                                    {isAccountDropdownOpen && (
                                        <div
                                            className="account-list show"
                                            onMouseEnter={handleAccountMouseEnter}
                                            onMouseLeave={handleAccountMouseLeave}
                                        >
                                            <ul className="drop-menu">
                                                <li className="flex">
                                                    <Link to="/points">
                                                        <img src={acc} alt="" />
                                                        <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]">
                                                            My Points
                                                        </p>
                                                    </Link>
                                                </li>
                                                <li className="flex">
                                                    <Link to="/profile">
                                                        <img src={acc1} alt="" />
                                                        <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]">
                                                            My Profile
                                                        </p>
                                                    </Link>
                                                </li>
                                                <li className="flex">
                                                    <Link to="/password">
                                                        <img src={acc2} alt="" />
                                                        <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]">
                                                            Change Password
                                                        </p>
                                                    </Link>
                                                </li>
                                                <li className="flex">
                                                    <div
                                                        className="flex items-center py-1 rounded cursor-pointer"
                                                        onClick={handleLogout}
                                                    >
                                                        <img src={acc3} alt="" className="mr-2" />
                                                        <p className="text-[14px] not-italic px-2 font-normal leading-[normal]">
                                                            Logout
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="flex gap-mob mt-nav-20">
                                        <Link to="/login">
                                            <div
                                                className={`flex justify-center items-center w-full loginbtn px-8 py-2 rounded-full transition-colors duration-300 ease-in-out
             `}
                                                onMouseEnter={handleLoginMouseEnter}
                                                onMouseLeave={handleLoginMouseLeave}
                                                onClick={handleLoginClick}
                                            >
                                                <p className="text-xs md:text-base not-italic font-normal leading-normal " style={{ color: hoveredLogin ? 'white' : '#00549a' }}>
                                                    Login
                                                </p>
                                                <div className="pl-4">
                                                    <img src={hoveredLogin ? logo3 : logo1} alt="Logo" />
                                                </div>
                                            </div>
                                        </Link>



                                        <Link to="/register">
                                            <div
                                                className={`flex justify-center items-center joinfcp  px-3 md:px-6 py-2 md:py-3 rounded-full bg-[${isActive ? '#00549A' : '#1AB78D'}] hover:bg-[#00549A]`}
                                                onClick={() => {
                                                    document.activeElement.blur();
                                                }}
                                            >
                                                <p className={`text-xs md:text-base not-italic font-normal leading-normal text-white`}>Join FCPI</p>
                                                <div className="ml-2 md:ml-4">
                                                    <img src={logo2} alt="Logo" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        );
    };

export default Home;