import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import axios from "axios";
import parse from 'html-react-parser';
import cap from "../../../assets/images/lineee.svg";
import blog1 from "../../../assets/images/Blogs.jpg";
import arrow from "../../../assets/images/arrow-right-01-round.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import "./blogdetail.css";

const BlogDetail = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isGeneralBlog, setIsGeneralBlog] = useState(false);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/admins/general-blogs-detail/${slug}/`);
                setIsGeneralBlog(true);
                setBlog(response.data);
            } catch (error) {
                
                try {
                    const response = await axios.get(`${BASE_URL}/admins/blogs-detail/${slug}/`);
                    setIsGeneralBlog(false);
                    setBlog(response.data);
                } catch (error) {
                    
                }
            }
            setLoading(false);
        };

        fetchBlogDetails();
    }, [slug]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px]">
                        <div className="grid grid-cols-1 md:grid-cols-2 bg-[#00549A] rounded-t-[30px] relative overflow-hidden brb-30 ptb-30">
                            <div className="blogcont">
                                <p className="text-white text-[32px] not-italic font-semibold leading-[normal] pl-50">Blogs</p>
                                <motion.div
                                    className="blogimg "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <img src={blog1} alt="Blog" className="w-full pt-12 rounded-tr-[30px]" />
                                </motion.div>
                            </div>
                            <div className="start position-relative z-10 pl-30">
                             

                                <p className="text-white text-[42px] pt-28 not-italic font-normal leading-[51px] blogdetailhead">{blog?.title}</p>
                                
                            </div>
                            <div className="bg-line">
                                <img src={cap} alt="Line" className="w-full pt-12 rounded-tr-[30px]" />
                            </div>
                        </div>

                        <div className="flex blog-details p-12">
                            <div className="blog-left">
                                <div>
                                    <p className="text-[#222] text-[21px] not-italic pt-2 font-medium leading-[normal]">Author</p>
                                    <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">{blog?.author || 'Unknown'}</p>
                                    {blog?.author_profile && <img className="blogautor-img" src={BASE_URL + blog?.author_profile} alt="Author Profile" />}
                                </div>

                                <p className="text-[#222] text-[21px] not-italic pt-8 font-medium leading-[normal]">Date</p>
                                <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">{formatDate(blog?.date)}</p>
                                <p className="text-[#222] text-[21px] not-italic pt-8 font-medium leading-[normal]">Forum Name</p>
                                <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">{blog?.forum_title}</p>
                            </div>
                            <div className="blog-right pt-12">
                               
                                
                                    {blog?.blog_contents && blog?.blog_contents.map((content, index) => (
                                        <div key={index}>
                                            <p className="text-[#00549A]   text-[25px] not-italic font-normal leading-[28px]">{content.topic}</p>
                                            <p className="text-[#58585A] pt-4 text-[18px] not-italic font-normal leading-[28px] blog-content">
                                                {parse(content.description)}  
                                            </p>
                                            <motion.div
                                                className=""
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 2,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                {content.image && <img className="blog-mainimg" src={BASE_URL + content.image} alt="Blog Content" />}
                                            </motion.div>
                                        </div>
                                    ))}
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetail;
